import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Flex, VStack, Heading } from '@chakra-ui/react'
import { useTiersQuery } from 'src/generated/graphql-frontend'
import { Tier } from 'components/Tier'

interface Props {
  profileId: string
  returnTo: string
}

export const MembershipsTab: React.FC<Props> = ({ profileId, returnTo }) => {
  const { t } = useTranslation('username')
  const { data } = useTiersQuery({ variables: { profileId } })
  return (
    <VStack w="full" spacing={{ base: '5', md: '6' }} alignContent="stretch">
      <Heading pt={{ base: '8', md: '0' }} fontSize={{ base: 'lg', md: 'xl' }}>
        {t('membershipChooseMembershipTier')}
      </Heading>
      <Flex w="full" flexWrap="wrap" justifyContent="center">
        {data?.tiers.map((tier) => (
          <Tier tier={tier} key={tier.id} m="0.5rem" returnTo={returnTo} />
        ))}
      </Flex>
    </VStack>
  )
}
