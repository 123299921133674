import { Button, Flex, Stack } from '@chakra-ui/react'
import { Description } from 'components/Description'
import { InputWrapper } from 'components/InputWrapper'
import { ModalOrDrawer } from 'components/ModalOrDrawer'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { useForm } from 'react-hook-form'

import type { CheckoutModalPromiseAction, ModalPromise } from 'src/types/general'
import { Cart } from '../Cart'

interface Props {
  username?: string
  tierId?: string
  modalPromise?: ModalPromise<CheckoutModalPromiseAction>
  /**
   * Whether order status is {@enum OrderStatusEnum.Paid}, which if cancelled will cause refund transaction fee upon merchant
   */
  isOrderAlreadyPaid?: boolean
  isLoading: boolean
  onClose: () => void
}

interface Form {
  message: string
}

export const CheckoutModal = ({
  username,
  isOrderAlreadyPaid,
  modalPromise,
  isLoading,
  onClose,
}: Props) => {
  const { t } = useTranslation('backOffice')

  const {
    register,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>()

  const isOpen = !!modalPromise
  React.useEffect(() => {
    if (isOpen) {
      reset()
    }
  }, [isOpen, reset])

  const handleClose = () => {
    modalPromise?.reject()
    onClose()
  }

  const handleFormSubmit = ({ message }: Form) => {
    modalPromise?.resolve(message)
  }

  const isApprove = modalPromise?.action?.tierId
  const translationKey = username
    ? 'Bookings.MultiApproveModal'
    : isOrderAlreadyPaid
      ? 'Bookings.CancelModal'
      : 'Bookings.RejectModal'

  return (
    <ModalOrDrawer
      title={t(`${translationKey}.title`)}
      description={
        isApprove
          ? undefined
          : t(`${translationKey}.description`)
      }
      maxW="container.sm"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Stack as="form" spacing={4} onSubmit={handleSubmit(handleFormSubmit)}>
        {username && (
          <Cart username={username}  />
        )}
          <Description as="div" color="peach.500">
            {t(`${translationKey}.multiple`)}
          </Description>
        {!isApprove && isOrderAlreadyPaid && (
          <Description as="div" color="red">
            {t(`${translationKey}.feeWarning`)}
          </Description>
        )}
        {!isApprove && (
          <InputWrapper
            {...register('message')}
            placeholder={t(`${translationKey}.messagePlaceholder`)}
          />
        )}
        <Flex gap={3} justify="flex-end">
          <Button variant="outline" onClick={onClose}>
            {t(`${translationKey}.close`)}
          </Button>
          {isApprove ? (
            <Button colorScheme="green" type="submit" isLoading={isSubmitting || isLoading}>
              {t(`${translationKey}.approveBtn`)}
            </Button>
          ) : (
            <Button colorScheme="red" type="submit" isLoading={isSubmitting || isLoading}>
              {t(`${translationKey}.rejectBtn`)}
            </Button>
          )}
        </Flex>
      </Stack>
    </ModalOrDrawer>
  )
}
